@use 'sass:color';
@use 'variables' as *;

.survey {
	h2 {
		font-size: 28px;
		font-weight: bold;
		margin-block-start: 18px;
		margin-block-end: 12px;

		strong {
			color: $color-fitblueelec;
		}
	}

	.ant-input-number {
		width: 120px;
		height: 60px;
		padding-left: 12px;
	}

	.ant-input {
		font-size: 24px;
		color: $color-fitblueelec;
		height: 60px !important;
		padding-left: 20px;
		padding-right: 20px;
	}

	.ant-input-number-input-wrap {
		height: 60px;
	}

	.ant-input-number-input {
		font-size: 42px;
		color: $color-fitblueelec;
		height: 60px !important;
		text-align: center;
		padding-right: 20px;
	}

	a {
		transition: all 0.3s ease-in-out;

		&.leave-survey {
			font-weight: bold;
			color: #999;

			&:hover {
				color: #f00;
			}
		}

		&.answer-later {
			font-weight: bold;
			color: #b386f9;

			&:hover {
				opacity: 0.7;
			}
		}
	}

	&.genderData h2 strong {
		color: $color-gspillar-genderdata;
	}

	&.fairCareerPath h2 strong {
		color: $color-gspillar-careerpath;
	}

	&.equalPay h2 strong {
		color: $color-gspillar-equalpay;
	}

	&.worklifeBalance h2 strong {
		color: $color-gspillar-worklifebalance;
	}

	&.deiActions h2 strong {
		color: $color-gspillar-deiactions
	}

	code {
		font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
	}

	&.ums {

		h2 {
			color: $color-fitblue;

			strong {
				color: $color-ums-purple;
			}
		}

		a {
			color: $color-ums-purple;

			&:hover {
				color: rgba($color-ums-purple, .6);
			}

			&.leave-survey {
				color: #fff;

				&:hover {
					color: #f99;
				}
			}

			&.answer-later {
				color: $color-ums-purple;
			}
		}

		.caption {
			color: $color-fitblue !important;
		}

		.sublabel {
			color: rgba($color-fitblue, .8) !important;
		}

		.ant-btn-link {

			&,
			&:not(:disabled) {
				color: $color-ums-purple;

				&:hover {
					color: rgba($color-ums-purple, .6);
				}
			}

		}

		textarea {
			height: 180px !important;
			width: 100%;
			font-size: 16px;
		}

		.ant-btn {
			background-color: transparent;
			border-color: rgba(65, 10, 10, 0.6);
			border-color: $color-fitblue;
			color: $color-fitblue;

			&:hover {
				background-color: rgba($color-fitblue, .2);
			}
		}

		.ant-btn-primary {
			background-color: $color-fitblue;
			border-color: $color-fitblue;
			color: #ffffff;

			&:hover {
				background-color: $color-ums-primary-hover;
				border-color: $color-ums-primary-hover;
			}

			&.submit {
				background-color: $color-ums-submit;
				border-color: $color-ums-submit;

				&:hover {
					background-color: lighten($color-ums-submit, 20%);
					border-color: lighten($color-ums-submit, 20%);
				}
			}
		}


		.ant-input {
			color: rgba(0, 0, 0);
			border-color: $color-ums-primary-hover;
			background-color: transparent;

			&:hover {
				color: $color-ums-primary-hover;
				border-color: color.scale($color-fitblue, $alpha: -40%);
				background-color: color.scale($color-fitblue, $alpha: -80%);
			}

			&:focus {
				color: $color-ums-primary-hover;
				border-color: color.scale($color-fitblue, $alpha: -40%);
				background-color: white;
			}
		}

		.ant-input-number-input {
			color: $color-ums-purple;
		}

		.ant-radio-button-wrapper {
			background-color: transparent;
			border-color: color.scale($color-fitblue, $alpha: -20%);
			color: $color-ums-primary-hover;

			&:hover {
				background-color: color.scale($color-fitblue, $alpha: -80%);
				border-color: color.scale($color-fitblue, $alpha: -20%);
				color: $color-ums-primary-hover;
			}

			&.ant-radio-button-wrapper-checked {
				background-color: $color-ums-primary-hover;
				border-color: color.scale($color-fitblue, $alpha: -20%);
				color: white;

				&::before {
					background-color: transparent !important;
				}
			}
		}

		.ant-radio-wrapper {
			border-color: transparent !important;

			.ant-radio-inner {
				border-color: color.scale($color-fitblue, $alpha: -60%);
			}

			.ant-radio-checked .ant-radio-inner {
				border-color: $color-ums-submit;
				background-color: $color-ums-submit;
			}
		}

		.ant-btn:hover .ant-radio-wrapper .ant-radio-inner {
			border-color: color.scale($color-fitblue, $alpha: -20%)
		}

		.ant-checkbox .ant-checkbox-inner {
			border-color: color.scale($color-fitblue, $alpha: -60%);
		}

		.ant-btn:hover .ant-checkbox .ant-checkbox-inner {
			border-color: color.scale($color-fitblue, $alpha: -20%)
		}

		.ant-checkbox-checked .ant-checkbox-inner {
			border-color: $color-ums-submit;
			background-color: $color-ums-submit;
		}
	}
}