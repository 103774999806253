@use 'variables' as *;
@use 'typography' as *;
@use 'buttons' as *;
@use 'survey' as *;
@use 'action-plan' as *;
@use 'search-talents' as *;

body {
	margin: 0;
}

a,
.ant-btn-link {
	color: $color-fitblueelec;

	&:hover,
	&:not(:disabled):hover {
		color: $color-antblue;
	}
}

.box {
	padding: 6px 12px;
	border: 1px solid #eee;
	border-radius: 5px;

	&.shadow {
		box-shadow: 0 5px 20px -8px color-black-alpha(.3);
	}
}


.app-banner a {
	color: $color-fitblueelec;
	color: white;
	text-decoration: underline;
}

.app-banner a:hover {
	color: $color-fitblueelec;
	text-decoration: none;
}


.user-stub .ant-avatar.ant-avatar-circle {
	display: 'none' !important;
}


/* *** UMS Survey *** */


pre.__json-pretty__ {
	font-size: 10px;
}

.filter-btn {
	transition: all 0.3s ease-out;
}

.filter-btn:hover {
	background: darkturquoise !important;
	color: white !important;
	box-shadow: none !important;
}

.filter-btn.filter-btn-active:hover {
	background: rgb(230, 246, 246) !important;
	color: darkturquoise !important;
	box-shadow: none !important;
}

.filter-clear-btn {}

.filter-clear-btn:hover {
	background: color-black-alpha(.8) !important;
	/*color: rgba(255,255,255,1) !important;*/
	color: white !important;
}

/* .ant-select.staff-company-select, */
.ant-select.staff-company-select .ant-select-selector {
	height: 42px;
	border-radius: 4px !important;
}

.ant-select-dropdown.staff-company-select {
	min-width: 340px !important;
}

.ant-popover {
	z-index: 1050 !important;
}

.ant-popover .ant-popover-inner {
	border-radius: 8px;
}

#search-talents-page .ant-card-body {
	padding: 0;
}

#search-talents-page .ant-card-body .ant-space {
	padding: 0;
}

#search-talents-page .ant-card-body .ant-space .ant-space-item {
	padding: 0 !important;
}

.ant-dropdown-menu {
	padding: 4px !important;
	border-radius: 8px !important;
}

.ant-dropdown-menu-item {
	border-radius: 4px !important;
}

.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
	border-top-left-radius: 6px !important;
	border-bottom-left-radius: 6px !important;
}

.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
	border-top-right-radius: 6px !important;
	border-bottom-right-radius: 6px !important;
}

.ant-btn.ant-btn-primary.action-item-remove {
	background: white;
	color: $color-fitblueelec;
	border-color: transparent;
}

.ant-btn.ant-btn-primary.action-item-remove:hover {
	background: #ff4d4f;
	color: white;
}

.ant-drawer .ant-btn.ant-btn-primary.action-item-remove {
	background: #ff4d4f;
	color: white;
	border-color: transparent;
}

.ant-drawer .ant-btn.ant-btn-primary.action-item-remove:hover {
	border-color: #ff4d4f;
	background: white;
	border-color: #ff4d4f;
	color: #ff4d4f;
}

.ant-group.ant-dropdown-button .ant-btn {
	border-radius: 6px 0 0 6px !important;
}

.ant-group.ant-dropdown-button .ant-btn.ant-dropdown-trigger {
	border-radius: 0 6px 6px 0 !important;
}

code {
	font-family: 'Red Hat Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace !important;
	color: #ff3333;
	font-weight: 700;
	background: #eee;
	padding: 2px 4px;
	display: inline-block;
	border-radius: 2px;
	/*font-size: 10px;*/
}

code.slug {
	color: #999;
	padding: 0 6px;
	font-weight: 600;
	background: #f9f9f9;
	/*font-style: italic;*/
}

code.tiny {
	color: #999;
	padding: 0 0;
	font-weight: 400;
	font-size: 8px;
	background: none;
}

.withAffix .ant-drawer-body {
	padding-top: 0 !important;
}

.ant-drawer-header-title {
	align-items: flex-start !important;
	flex-direction: row-reverse;
}

.ant-drawer .ant-drawer-content {
	overflow: visible;
}

.ant-drawer-header-title .ant-drawer-close {
	position: absolute;
	color: white;
	left: -70px;
	top: 20px;
	background: black;
	width: 52px;
	height: 52px;
	border-radius: 52px;
	/*padding: 0;*/
	line-height: 0;
	transition: all 0.2s ease-out;
}

.ant-drawer-header-title .ant-drawer-close:focus,
.ant-drawer-header-title .ant-drawer-close:hover {
	background: #333;
	color: #eee;
}

.ant-spin-container .withAffixedHead {
	margin-top: -24px;
}

.ant-card-head-title {
	font-size: 16px;
	font-weight: bold;
}

.ant-card-section {
	margin: -24px;
	padding: 24px;
	width: calc(100% + 48px);
}

.affixedHead {
	padding: 20px 24px;
	background: white;
	width: 100%;
	border-bottom: 1px solid #eee;
	box-shadow: 0 10px 30px -25px color-black-alpha(.5);
}

#components-layout-demo-fixed .logo {
	float: left;
	width: 120px;
	height: 31px;
	margin: 16px 24px 16px 0;
	background: rgba(255, 255, 255, 0.2);
}

.flexRow {
	display: flex;
}

.title18 {
	font-size: 18px !important;
}

.title24 {
	font-size: 24px !important;
}

.flexRow {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	flex-wrap: wrap;
}

.alignStart {
	align-items: flex-start;
}

.alignCenter {
	align-items: center;
}

.flexEnd {
	justify-content: flex-end;
}

.flexColumn {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.flexColumn.alignStart {
	align-items: flex-start;
}

.flexStretch {
	display: flex;
	flex-direction: column;
	align-items: stretch;
}

.flexCenter {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
}

.justifyCenter {
	justify-content: center;
}

.flex1 {
	flex: 1
}

.nowrap {
	flex-wrap: nowrap;
}

.spaceBetween {
	justify-content: space-between;
}

.spaceAround {
	justify-content: space-around;
}

input,
.ant-btn,
.ant-notification,
.ant-message-notice-content,
.ant-modal,
[class^="ant-space"],
[class*=" ant-space"],
[class^="ant-notification"],
[class*=" ant-notification"] {
	// font-family: 'Space Grotesk' !important;
	font-family: 'DM Sans' !important;
}

.ant-notification-notice-content {
	margin-left: 5px;
}

.article-image-block img {
	display: block;
}

/* .ant-notification-notice-icon {
	margin-right: 20px;
} */

/* .ant-notification-notice-with-icon {
	padding-left: 20px;
} */

.ant-message-notice-content {
	margin-left: 20px;
	margin-right: 20px;
	/*max-width: 90vw;*/
}

.funnel-filters.ant-form-vertical .ant-form-item-label {
	padding: 0;
}

.ant-form-item-with-help {
	margin-bottom: 24px !important;
	/*    transition: none !important;*/
}

/*.ant-form-item-with-help .ant-form-item-explain {*/
/*    transition: none !important;*/
/*}*/

/*.ant-form-item {*/
/*    transition: none !important;*/
/*}*/

/*.ant-row.ant-form-item.ant-form-item-with-help.ant-form-item-has-success {*/
/*    transition: none !important;*/
/*}*/

.login .ant-form-item-with-help {
	margin-bottom: unset !important;
}

.ant-form-item-explain,
.ant-form-item-extra {
	padding-top: 2px;
	font-size: 12px !important;
	line-height: 1.3 !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
	display: none !important;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
	display: inline-block;
	margin-right: 0;
	color: #ff4d4f;
	font-size: 24px;
	font-weight: 800;
	font-family: SimSun, sans-serif;
	line-height: 1;
	content: '*';
}

.rawHtml ul {
	padding-left: 14px;
}

.ant-menu-submenu {
	padding-bottom: 0px !important;
}

.ant-menu-item {
	padding-left: 12px;
	/* margin-left: 0; */
	/* margin-right: 0; */
	/* border-radius: 0; */
}

.ant-menu-item svg {
	color: #333;
}

.ant-menu-item:hover svg,
.ant-menu-submenu-active svg,
.ant-menu-item-selected svg {
	color: $color-fitblueelec !important;
}

.ant-menu-submenu-active::after,
.ant-menu-item-selected::after {
	width: 2px;
	background: $color-fitblueelec;
}

.ant-tooltip .ant-tooltip-inner {
	border-radius: 6px;
	background-color: 'rgba(0,0,0,.8)';
	padding: 6px 12px;
	box-shadow: none;
}

.ant-tooltip .ant-tooltip-arrow::after {
	box-shadow: none;
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a {
	color: black !important;
}

.ant-tooltip.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a:hover {
	color: $color-fitblueelec !important;
}

/*.ant-menu-inline-collapsed-tooltip .ant-badge {*/
/*  color: white !important;*/
/*}*/
.ant-menu-inline-collapsed-tooltip .ant-tooltip-arrow-content {
	background-color: white !important;
}

.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner {
	background-color: white !important;
}

.ant-menu-title-content {
	font-size: 14px !important;
	font-weight: 500 !important;
}

/*.ant-menu-item .ant-menu-title-content .ant-badge,*/
/*.ant-menu-submenu .ant-badge*/
.ant-menu-inline-collapsed-tooltip {
	font-size: 15px !important;
	font-weight: 600 !important;
	color: black;
}

.ant-menu-new .ant-menu-item,
.ant-menu-new .ant-menu-submenu-title {
	margin: 0 !important;
	border-radius: 0;
	width: 100%;
	font-family: 'DM Sans'
}

.ant-menu-new .ant-menu-item-divider {
	margin: 10px 0px !important;
	width: 100% !important;
}

.ant-menu-item a span,
.ant-menu-item .ant-menu-item-icon+span,
.ant-menu-submenu .ant-menu-item-icon+span,
.ant-menu-item .anticon+span,
.ant-menu-submenu .anticon+span {
	font-size: 14px !important;
	font-weight: 500 !important;
}

.ant-menu-new .ant-menu-item a span,
.ant-menu-new .ant-menu-item .ant-menu-item-icon+span,
.ant-menu-new .ant-menu-submenu .ant-menu-item-icon+span,
.ant-menu-new .ant-menu-item .anticon+span,
.ant-menu-new .ant-menu-submenu .anticon+span {
	font-size: 13px !important;
	font-weight: 400 !important;
}

.ant-menu-new .ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon,
.ant-menu-new .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon,
.ant-menu-new .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-new .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-new .ant-menu.ant-menu-inline-collapsed>.ant-menu-item .anticon,
.ant-menu-new .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon,
.ant-menu-new .ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon,
.ant-menu-new .ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
	padding-left: 0 !important;
	font-size: 18px !important;
}

.ant-menu-item-divider {
	margin: 0 24px !important;
	border: none !important;
	border-block-start-color: rgba(5, 5, 5, 0.06) !important;
	border-block-start-style: solid !important;
	border-block-start-width: 1px !important;
	clear: both !important;
	display: flex !important;
	height: 1px !important;
	line-height: 22px !important;
	width: 215px !important;
}

span.logoNew,
.ant-menu-item a span.new,
.ant-menu-item .ant-menu-item-icon+span span.new,
.ant-menu-submenu .ant-menu-item-icon+span span.new,
.ant-menu-item .anticon+span span.new,
.ant-menu-submenu .anticon+span span.new {
	font-size: 9px !important;
	font-weight: 800 !important;
	background: #F7506E !important;
	color: white;
	padding: 1px 3px;
	border-radius: 2px;
	margin-left: 4px;
	margin-right: 2px;
	margin-bottom: 2px;
	vertical-align: middle;
}

span.logoOpen,
.ant-menu-item a span.menu-tag,
.ant-menu-item .ant-menu-item-icon+span span.menu-tag,
.ant-menu-submenu .ant-menu-item-icon+span span.menu-tag,
.ant-menu-item .anticon+span span.menu-tag,
.ant-menu-submenu .anticon+span span.menu-tag {
	font-size: 9px !important;
	font-weight: 800 !important;
	background: #00ced1 !important;
	color: white;
	padding: 1px 3px;
	border-radius: 10px;
	margin-left: 4px;
	margin-right: 2px;
	margin-bottom: 2px;
	vertical-align: middle;
}

span.menu-tag.menu-tag-open {
	background: #00ced1 !important;
	color: white;
}

span.menu-tag.menu-tag-filled {
	background: #00ced1 !important;
	color: white;
}

span.menu-tag.menu-tag-todo {
	background: #d100ca !important;
	color: white;
}

.ant-badge {
	color: unset !important;
}

.ant-menu-item a {
	color: #223 !important;
}

.ant-menu-item.ant-menu-item-active a,
.ant-menu-item.ant-menu-item-active .ant-menu-item-icon+span,
.ant-menu-submenu.ant-menu-submenu-active .ant-menu-item-icon+span,
.ant-menu-item.ant-menu-item-active .anticon+span,
.ant-menu-submenu.ant-menu-submenu-active .anticon+span {
	/*color: grey !important;*/
	color: $color-fitblueelec !important;
}

.ant-menu-inline.ant-menu-root .ant-menu-item .ant-menu-item-icon,
.ant-menu-inline.ant-menu-root .ant-menu-submenu-title .ant-menu-item-icon {
	font-size: 20px;
}

.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon,
.ant-menu.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
	padding-left: 0 !important;
	font-size: 20px !important;
}

.ant-form-item-label>label {
	padding-right: 10px;
}

.ant-form-item-label>label::after {
	display: none;
}

h1.ant-typography,
.ant-typography h1 {
	font-size: 32px !important;
}

.ant-form-item-control-input-content {
	display: flex
}

.ant-input {
	font-size: 14px;
	/* padding: 0 6px; */
	/* height: unset; */
	border-radius: 4px !important;
}

.ant-input-group>.ant-input:first-child,
.ant-input-group-addon:first-child {
	border-radius: 4px 0 0 4px !important;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
	border-radius: 0 4px 4px 0 !important;
}

.ant-input-password.ant-input-affix-wrapper {
	border-radius: 4px !important;
}

.ant-tour {
	z-index: 1050 !important;
}

/*
Ant Switch - Large
*/

.ant-switch.ant-switch-large,
.ant-tooltip-disabled-compatible-wrapper.ant-switch-large .ant-switch {
	height: 32px;
}

.ant-switch.ant-switch-large .ant-switch-inner,
.ant-tooltip-disabled-compatible-wrapper.ant-switch-large .ant-switch .ant-switch-inner {
	font-size: 14px;
	margin: 4px 10px 0 14px;
	border-radius: unset;
}

.ant-switch.ant-switch-large .ant-switch-inner .ant-switch-inner-checked,
.ant-switch.ant-switch-large .ant-switch-inner .ant-switch-inner-unchecked,
.ant-tooltip-disabled-compatible-wrapper.ant-switch-large .ant-switch .ant-switch-inner .ant-switch-inner-checked,
.ant-tooltip-disabled-compatible-wrapper.ant-switch-large .ant-switch .ant-switch-inner .ant-switch-inner-unchecked {
	font-size: 14px;
}

.ant-switch.ant-switch-large.ant-switch-checked .ant-switch-inner,
.ant-tooltip-disabled-compatible-wrapper.ant-switch-large .ant-switch.ant-switch-checked .ant-switch-inner {
	margin-right: 14px;
}

.ant-switch.ant-switch-large .ant-switch-handle,
.ant-tooltip-disabled-compatible-wrapper.ant-switch-large .ant-switch .ant-switch-handle {
	width: 28px;
	height: 28px;
}

.ant-switch.ant-switch-large.ant-switch-checked .ant-switch-handle,
.ant-tooltip-disabled-compatible-wrapper.ant-switch-large .ant-switch.ant-switch-checked .ant-switch-handle {
	left: calc(100% - 28px - 2px);
}

.ant-switch.ant-switch-large .ant-switch-handle::before,
.ant-tooltip-disabled-compatible-wrapper.ant-switch-large .ant-switch .ant-switch-handle::before {
	border-radius: 28px;
}

/*
Ant Button - Colors
*/
.ant-btn-blue50.ant-btn-primary {
	background-color: $color-fitblueelec !important;
}

.ant-btn-blue50.ant-btn-primary:disabled {
	background-color: color-black-alpha(0.04) !important;
}

.ant-btn-blue50.ant-btn-default:hover {
	color: $color-fitblueelec !important;
	border-color: $color-fitblueelec !important;
	background-color: white !important;
}

.ant-btn-blue50.ant-btn-primary.ant-btn-background-ghost {
	color: $color-fitblueelec !important;
	border-color: $color-fitblueelec !important;
	background-color: white !important;
}

.ant-dropdown-btn-blue50 .ant-btn-primary {
	background-color: $color-fitblueelec !important;
}

/*
Ant Menu - Colors
 */

.ant-menu-blue50 .ant-dropdown-menu-item {
	color: $color-fitblueelec !important;
}

/*
Ant Text - Colors
 */

.ant-text-blue50 {
	color: $color-fitblueelec !important;
}

.ant-link-blue50 a {
	color: $color-fitblueelec !important;
}

/*
Ant Spin - Colors
 */

.ant-spin-blue50 .ant-spin-dot-item {
	background-color: $color-fitblueelec !important;
}

/*
Ant Switch - Colors
 */

.ant-switch-checked {
	background-color: darkturquoise !important;
}

.ant-switch-checked.ant-switch-orange {
	background-color: orange !important;
}

.ant-switch-checked.ant-switch-blue50 {
	background-color: $color-fitblueelec !important;
}

.ant-switch.ant-switch-unchecked-orange {
	background-color: orange;
}

.ant-switch.ant-switch-unchecked-blue50 {
	background-color: $color-fitblueelec;
}

.ant-switch.ant-switch-unchecked-darkturquoise {
	background-color: darkturquoise;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border: none !important;
}

.table-feature-compare table {
	/* border-radius: 20px !important; */
	border-color: #cccccc !important;
}

.table-feature-compare .ant-table-container {
	border-radius: 10px 10px 0px 0px !important;
	border-color: #cccccc !important;
}

.table-feature-compare .ant-table-cell {
	border-color: #cccccc !important;
}

.table-feature-compare .ant-table {
	/* background: #f5f5f7; */
	/* background: #d0f0f2; */
}

.table-feature-compare .ant-table-thead .ant-table-cell {
	background: #e5e5f7;
}

.table-feature-compare .ant-table-thead .ant-table-cell:nth-child(2) {
	background: #0000ff;
	color: #ffffff;
}

.table-feature-compare .ant-table-tbody .ant-table-cell:nth-child(2) {
	background: #eeeeff;
}

.ant-table.ant-table-middle .ant-table-thead>tr>th {
	padding: 12px 6px !important;
}

/*.ant-table-expanded-row-fixed {*/
/*  background: #eee;*/
/*  box-shadow: inset 0 10px 20px -12px rgba(0,0,0, 0.5);*/
/*}*/
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-tbody>tr>td,
.ant-table.ant-table-middle tfoot>tr>th,
.ant-table.ant-table-middle tfoot>tr>td {
	padding: 6px 6px;
}

.ant-table-title {
	background: #f5f5f7;
}

.ant-table-thead .ant-table-column-title,
.ant-table-thead .ant-table-cell {
	font-weight: bold;
	line-height: 1.2;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ant-table-row.stopped {
	/*background: #ffeeee;*/
	/*opacity: .2;*/
}

.ant-table.ant-table-middle {
	font-size: 12px !important;
}

.rowNeverLogged .ant-table-cell {
	background: #fff5f7 !important;
}

.rowDisabled .ant-table-cell {
	background: #f5f5f7 !important;
}

.rowDisabled .ant-table-cell>* {
	opacity: 0.5;
}

/* .rowPending .ant-table-cell {
	background: #f5f5ff !important;
} */

.rowWeekend .ant-table-cell {
	background: #f9f9fb !important;
}

a.link {
	text-decoration: underline;
}

a.link:hover {
	text-decoration: none;
}

.ant-tabs-nav-list {
	padding: 5px 5px 0;
}

.ant-tabs-tab {
	cursor: pointer;
	padding: 10px 20px !important;
	font-weight: 700;
	transition: all 0.4s ease-out;
	border-radius: 6px 6px 0 0;
	box-sizing: content-box;
	background: #f9f9fb !important;
	border: 1px solid #eee !important;
	border-bottom: none !important;

	/*margin: 0 !important;*/
	/*color: #0d2b79 !important;*/
}

.ant-tabs-tab:hover {
	color: #0000ff !important;
}

.ant-tabs-tab+.ant-tabs-tab {
	margin: 0 0 0 4px !important;
}

.ant-tabs-tab-btn {}

.ant-tabs-tab-active {
	/*background: #fae1df !important;*/
	display: inline-block;
	/*color: #fff;*/
	/*box-shadow: 0 0.5rem 0.8rem #00000080;*/
}

.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:focus,
.ant-tabs-tab-btn:active,
.ant-tabs-tab-remove:active {
	/*color: #668899 !important;*/
	/*color: #ffffff !important;*/
}

.ant-tabs-tab:hover,
.ant-tabs-tab-remove:hover {
	background: #eeeeee !important;
	color: #0000ff !important;
}

.ant-tabs-tab:focus,
.ant-tabs-tab:active {
	/*background: #eeeeee !important;*/
	background: #eeeeff !important;
	/*color: #aaffff !important;*/
}

.ant-tabs-tab.ant-tabs-tab-active {
	background: #eeeeff !important;
	color: #0000ff !important;
	/*color: #e03e39 !important;*/
	/*text-shadow: 0 0 0.25px currentColor;*/
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #0000ff !important;
	/*  !*color: #e03e39 !important;*!*/
	/*  text-shadow: 0 0 0.25px currentColor;*/
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
	/*height: 2px !important;*/
}

.ant-tabs-ink-bar {
	height: 2px !important;
	background: #0000ff !important;
}


.ant-btn.ant-btn-default.icon-circle-button {
	color: #777;
}

.add-button.ant-btn-primary[disabled] {
	background: white !important;
	border: 1px solid #eee !important;
}

.ant-select-selector {
	border-radius: 2px !important;
	padding: 20px;
	/*height: 40px !important;*/
}

.ant-select.job-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select.job-select.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	height: 72px;
}

.ant-select.job-select .ant-select-arrow,
.ant-select.job-select .ant-select-clear {
	/*margin-top: -18px;*/
}

.ant-checkbox-checked .ant-checkbox-inner {
	/*background-color: #0d2b79 !important;*/
	/*border-color: #0d2b79 !important;*/
}

.ant-pagination-item-active {
	background-color: $color-fitblueelec !important;
	border-color: $color-fitblueelec !important;
}

.ant-pagination-item-active a {
	color: #fff !important;
}

.ant-input-password {
	border-radius: 8px !important;
	height: 40px;
}

/* 
.displayTable {
  display: table
}
.displayTable > div{
  display: table-row
}
.displayTable > div > div{
  display: table-cell
}

.width100 {
  width: 100%;
} */

#components-layout-demo-custom-trigger .trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
	/*color: $color-fitblueelec;*/
}

#components-layout-demo-custom-trigger .logo {
	height: 32px;
	margin: 16px;
	background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
	background: #fff;
}

.site-layout {
	/*min-height: 95vh;*/
	min-height: 100vh;
}

.user-filter-form .ant-collapse-header {
	padding: 0 !important
}

/* ---------------------------------------- */
/* -------- CK Editor CSS settings -------- */
/* ---------------------------------------- */

.ck.ck-toolbar.ck-toolbar_grouping>.ck-toolbar__items {
	flex-wrap: wrap !important;
}

.ck.ck-editor {
	min-width: 100%;
}

.ck-content {
	font-size: 14px;
	font-weight: 400;
}

.ant-input-textarea-show-count {
	width: 100% !important;
	font-size: 14px;
}

iframe#webpack-dev-server-client-overlay {
	display: none !important
}

/** use this to fix "ResizeObserver loop limit exceeded" instead props "scroll" in  antd table  */
.ant-table-content {
	overflow: auto
}

.json-popover-link a svg {
	/*using grey because it's only for admin use and not a CTA*/
	color: #9D9D9D !important;
}

.staff-manager-panel-btn {
	transition: opacity .2s ease-out;
}

.staff-manager-panel-btn:hover {
	opacity: 1 !important;
}

.modal-large {
	.ant-modal {
		top: 20px;
	}

	.ant-modal-content {
		border-radius: 20px !important;
	}

	.ant-modal-header {
		border-radius: 20px 20px 0 0 !important;
	}

	.ant-modal-close {
		top: 15px !important;
		width: 36px;
		height: 36px;
		background: #ee6d62;
		color: white;
		border-radius: 100px !important;
		font-size: 18px;
		margin-top: 5px;

		&:hover {
			background: white;
			color: #ee6d62;
		}

		.ant-modal-close-x {
			margin-bottom: -2px;
		}
	}

	.ant-modal-footer {
		display: flex;
		justify-content: space-between;
		align-items: center;
		position: relative;
		z-index: 2;
		height: 48px;
		background: white;
	}

	.ant-text-blue50 {
		color: $color-fitblueelec !important;
	}

	&.no-close .ant-modal-close {
		display: none;
	}

	&.no-padding .ant-modal-content {
		padding: 0;
	}
}

.no-footer .ant-modal-footer {
	display: none;
}

.ant-btn-primary {
	background-color: $color-fitblueelec;
	border: 1px solid $color-fitblueelec;

	&:hover {
		background-color: #3F7DFF;
		border: 1px solid #3F7DFF;
	}

	&:disabled {
		background-color: color-black-alpha(0.04);
		color: rgb(157, 157, 157);
		border: 1px solid #eee;
	}

	&.ant-btn-dangerous {
		background-color: #ff4d4f;
		border: 1px solid transparent;
	}

	&.ant-btn-background-ghost {
		color: $color-fitblueelec;
		border: 1px solid $color-fitblueelec;
		background-color: white;
	}
}

.ant-btn-default {
	&:hover {
		color: $color-fitblueelec;
		border: 1px solid $color-fitblueelec;
		background-color: white;
	}

	&:disabled:hover {
		border-color: #d9d9d9;
		color: color-black-alpha(0.25);
		background-color: color-black-alpha(0.04);
	}
}

#job-form .ant-form-item,
#job-form .ant-form-item-with-help {
	margin-bottom: 30px !important;
}

#job-form .ant-select-selector,
#job-form .ant-input-number {
	font-size: 14px;
	/* padding: 0 6px; */
	/* height: unset; */
	border-radius: 4px !important;
}

#job-form .ck-placeholder {
	color: #ddd;
}

#job-form .ck-content {
	max-height: 400px;
}

#job-form #draft-button {
	width: 100%;
	margin: 0;
	font-weight: bold;
	border-radius: 5px !important;
	color: $color-fitblueelec;
	border-color: $color-fitblueelec;
}

#job-form #draft-button:hover {
	background-color: $color-fitblueelec;
	color: white;
	border-color: white;
}

#genderscore-countdown-banner .ant-btn-default {
	font-size: 16px;
	height: unset;
	font-weight: bold;
	color: white;
	border: 1.5px solid white;
	background-color: rgba(255, 255, 255, .3);
}

#genderscore-countdown-banner .ant-btn-default:hover {
	color: #b877ff;
	background-color: white;
}

#genderscore-expired-banner .ant-btn-default {
	font-size: 16px;
	height: unset;
	font-weight: bold;
	color: white;
	border: 1.5px solid white;
	background-color: rgba(255, 255, 255, .3);
}

#genderscore-expired-banner .ant-btn-default:hover {
	color: #e26f6c;
	background-color: white;
}

#companies-table .rowDisabledNotForceBg .ant-table-cell {
	background: #f5f5f7;
}

#companies-table .rowDisabledNotForceBg .ant-table-cell>* {
	opacity: 0.6 !important;
}

#gs-columns-toggle.ant-switch-checked {
	background: $color-lilac !important;
}

#genderscore-settings .menu-button {
	border-radius: 5px;
	padding: 20px;
	margin: 10px;
	margin-left: 75px;
	cursor: pointer;
	width: 400px;
}

#genderscore-settings .menu-button:hover {
	opacity: 0.8;
}

#genderscore-legend td.ant-table-cell {
	border: none !important;
	background-color: #ffffff !important;
	padding: 0 !important;
}

#genderscore-legend td.ant-table-cell:hover,
#genderscore-legend tr:hover {
	background-color: #ffffff !important;
	cursor: default !important;
}

.pdf li {
	margin-bottom: 10px;
}

#ums-settings .menu-button {
	border-radius: 5px;
	padding: 20px;
	margin: 10px;
	margin-left: 75px;
	cursor: pointer;
	width: 400px;
}

#ums-settings .menu-button:hover {
	opacity: 0.8;
}

#ums-legend td.ant-table-cell {
	border: none !important;
	background-color: #ffffff !important;
	padding: 0 !important;
}

#ums-legend td.ant-table-cell:hover,
#ums-legend tr:hover {
	background-color: #ffffff !important;
	cursor: default !important;
}