@use 'variables' as *;

.action-content {
	display: grid;
	grid-template-columns: 400px 1fr;
	width: 100%;
	max-height: calc(100vh - 60px);

	.left-side {
		.action-label {
			font-family: 'DM Sans';
			font-size: 12;
			font-weight: bold;
			color: $color-fitblueelec;
			margin-bottom: 4;
		}

		.action-title {
			font-family: 'DM Sans';
			font-size: 26px;
			font-weight: bold;
			line-height: 1.2,
		}

		.subbox {
			padding: 20px 24px;

			&.divider {
				border-bottom: 1px solid color-black-alpha(.1);
			}
		}
	}

	.right-side {
		padding: 30px;
		max-width: 100%;
		// max-height: calc(100% - 60px);
		max-height: calc(100vh - 80px);
		background: #fcfcfe;
		overflow-y: scroll;
	}
}

.action-modal {
	.action-content {
		margin: -20px -24px -32px;
		width: calc(100% + 48px);

		.right-side {
			max-height: calc(100vh - 60px - 60px);
		}
	}
}

.action-genderData {
	.color-container {
		background-image: radial-gradient(circle at 100% 0, #90ffbf, #a4f9ff 60%);
		background-image: radial-gradient(circle at 100% 0, #d8fbee, #dfe7fc 60%);
	}

	.action-title {
		color: #003164;
	}
}

.action-fairCareerPath {
	.color-container {
		background-image: radial-gradient(circle at 100% 0, #e9f59e, #b3ffd7 60%);
		background-image: radial-gradient(circle at 100% 0, #f2f7d1, #e2ffef 60%);
	}

	.action-title {
		color: #003d3d;
	}
}

.action-equalPay {
	.color-container {
		background-image: radial-gradient(circle at 100% 0, #ffdd95, #ffab8e 60%);
		background-image: radial-gradient(circle at 100% 0, #fcddc9, #f8f0df 60%);
	}

	.action-title {
		color: #381a0a;
	}
}

.action-worklifeBalance {
	.color-container {
		background-image: radial-gradient(circle at 100% 0, #b8edff, #e7c8ff 60%);
		background-image: radial-gradient(circle at 100% 0, #daf5fd, #f3e3ff 60%);
	}

	.action-title {
		color: #36146f;
	}
}

.action-deiActions {
	.color-container {
		background-image: radial-gradient(circle at 100% 0, #ffe5e1, #ffb1df 60%);
		background-image: radial-gradient(circle at 100% 0, #fbe8f3, #fdd7e2 60%);
	}

	.action-title {
		color: #47032b;
	}
}

.action-card,
.action-card.box {
	position: relative;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	width: 100%;
	min-height: 230px;
	padding: 20px;
	// border-color: #ddd;
	border-radius: 8px;
	background: white;
	font-family: 'DM Sans';
	color: rgb(0, 31, 31);
	border: none;

	box-shadow: inset 0 0 0 1px rgba(0, 61, 61, .04);
	border-radius: 22px;

	&.draggable {
		margin-bottom: 20px
	}

	&.completion-status-done {
		opacity: 0.7;
	}

	&.genderData {

		&.box,
		.box {
			background-image: radial-gradient(circle at 100% 0, #90ffbf, #a4f9ff 60%);
			background-image: radial-gradient(circle at 100% 0, #d8fbee, #dfe7fc 60%);
		}

		.title {
			color: #003164;
		}
	}

	&.fairCareerPath {

		&.box,
		.box {
			background-image: radial-gradient(circle at 100% 0, #e9f59e, #b3ffd7 60%);
			background-image: radial-gradient(circle at 100% 0, #f2f7d1, #e2ffef 60%);
		}

		.title {
			color: #003d3d;
		}
	}

	&.equalPay {

		&.box,
		.box {
			background-image: radial-gradient(circle at 100% 0, #ffdd95, #ffab8e 60%);
			background-image: radial-gradient(circle at 100% 0, #fcddc9, #f8f0df 60%);
		}

		.title {
			color: #381a0a;
		}
	}

	&.worklifeBalance {

		&.box,
		.box {
			background-image: radial-gradient(circle at 100% 0, #b8edff, #e7c8ff 60%);
			background-image: radial-gradient(circle at 100% 0, #daf5fd, #f3e3ff 60%);
		}

		.title {
			color: #36146f;
		}
	}

	&.deiActions {

		&.box,
		.box {
			background-image: radial-gradient(circle at 100% 0, #ffe5e1, #ffb1df 60%);
			background-image: radial-gradient(circle at 100% 0, #fbe8f3, #fdd7e2 60%);
		}

		.title {
			color: #47032b;
		}
	}

	&.action-plan {
		.title {
			color: #ffffff;
		}

		.genderData {

			&.box,
			.box {
				background-image: radial-gradient(circle at 100% 0, #1295ad, #046 60%);
			}

			// .title {
			// 	color: #cdface;
			// }
		}

		.fairCareerPath {

			&.box,
			.box {
				background-image: radial-gradient(circle at 100% 0, #008c63, #00664e 30%, #003d3d 70%);
			}

			// .title {
			// 	color: #cdface;
			// }
		}

		.equalPay {

			&.box,
			.box {
				background-image: radial-gradient(circle at 100% 0, #ffe0a3, #ffadb2 60%);
			}

			// .title {
			// 	color: #cdface;
			// }
		}

		.worklifeBalance {

			&.box,
			.box {
				background-image: radial-gradient(circle at 100% 0, #bfbfff, #dcbeff 60%);
			}

			// .title {
			// 	color: #cdface;
			// }
		}

		.deiActions {

			&.box,
			.box {
				background-image: radial-gradient(circle at 100% 0, #FFF0F6, #C41C7F 60%);
			}

			// .title {
			// 	color: #ffffff;
			// }
		}
	}
}

.ant-modal-content {
	.action-card {
		// width: unset;
	}
}

.action-modal {
	.action-card {
		// width: calc(100% - 42px);
		padding: 0;
		border: none;
		border-radius: 0;
		width: 100%;
		height: 100%;
		justify-content: flex-start;
		align-items: flex-start;

		&.completion-status-done {
			opacity: unset;
		}

		.subbox {
			padding: 20px 24px;
			width: calc(100% - 48px);

			&.divider {
				border-bottom: 1px solid color-black-alpha(.1);
			}
		}

		.action-label {
			font-family: 'DM Sans';
			font-size: 12;
			font-weight: bold;
			color: $color-fitblueelec;
			margin-bottom: 4;
		}

		.title {
			font-family: 'DM Sans';
			font-size: 26px;
			font-weight: bold;
			line-height: 1.2,
		}
	}

	.card-divider {
		padding: 20;
		border-bottom: 1px solid color-black-alpha(.1);
	}
}

.ant-segmented.action-plan {
	padding: 0;
	background: color-black-alpha(.1);
	border: none;

	.ant-segmented-group {
		border: none;
	}

	.ant-segmented-thumb {
		background: $color-fitblueelec;
	}

	&.ant-segmented-block {

		.ant-segmented-item {
			&.ant-segmented-item-selected {
				background: $color-fitblueelec;
			}
		}
	}
}