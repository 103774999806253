@use 'sass:color';

$color-darkturquoise: #00ced1;
$color-fitblue: #101582;
$color-fitblueelec: #0F3DFB;
$color-lilac: #881cff;
$color-antblue: #1677ff;

$color-ums-purple: #7a38e6;
$color-ums-primary-hover: #282e9d;
$color-ums-submit: #FF635B;

$color-gspillar-genderdata: #0959D9;
$color-gspillar-careerpath: #09979C;
$color-gspillar-equalpay: #D48806;
$color-gspillar-worklifebalance: #531DAB;
$color-gspillar-deiactions: #C41C7F;

@function color-black-alpha($alpha) {
	@return rgba(black, $alpha);
}