@use 'sass:color';
@use 'variables' as *;

.search-talents-tabs {

	&.ant-tabs>.ant-tabs-nav,
	&.ant-tabs>div>.ant-tabs-nav {
		padding-left: 20px;
		margin: 0;
	}

	.ant-tabs-tab {
		font-size: 16px;
		transition: all 0.4s ease-out;
		border-radius: 8px 8px 0 0;
		box-sizing: content-box;
		background: #ffffff !important;
		border: 1px solid #eee !important;
		/*border-bottom: none !important;*/

		&:hover {
			background: #eeeeee !important;
			color: $color-fitblueelec !important;
		}

		&:focus,
		&:active {
			background: #ffffff !important;
			border-bottom: none !important;
		}

		&.ant-tabs-tab-active {
			background: #ffffff !important;
			color: $color-fitblueelec !important;
			border-bottom: none !important;

			.ant-tabs-tab-btn {
				color: $color-fitblueelec !important;
			}
		}

		&+.search-talents-tabs .ant-tabs-tab {
			margin: 0 0 0 8px !important;
		}
	}

	.ant-tabs-tab-remove {

		&:hover {
			background: #eeeeee !important;
			color: $color-fitblueelec !important;
		}
	}

	.ant-tabs-ink-bar {
		height: 0 !important;
		/*background: $color-fitblueelec !important;*/
	}
}

#search-talents-page,
.user-profile {

	.suggestions-divider {
		&.ant-divider-horizontal {

			&::before,
			&::after {
				/*darkturquoise*/
				border-top: 1px solid $color-darkturquoise;
			}
		}
	}

	#suggested-for-you {
		&.ant-btn-primary {
			/*darkturquoise*/
			background-color: $color-darkturquoise !important;
			border: 1px solid $color-darkturquoise !important;

			.logo50 {
				background-color: white !important;
			}
		}

		&.ant-btn-default {
			/*darkturquoise*/
			color: $color-darkturquoise !important;
			border: 1px solid $color-darkturquoise !important;
			background-color: white !important;

			&:hover {
				/*darkturquoise*/
				color: white !important;
				border: 1px solid $color-darkturquoise !important;
				background-color: $color-darkturquoise !important;

				.logo50 {
					background-color: white !important;
				}
			}

			.logo50 {
				/*darkturquoise*/
				background-color: $color-darkturquoise !important;
			}
		}
	}

	.engage-buttons {

		.ant-btn-primary {
			font-weight: bold;
		}

		.ant-btn-default {
			background-color: white !important;
			border: 1px solid $color-fitblueelec !important;
			color: $color-fitblueelec !important;

			&:hover {
				background-color: $color-fitblueelec !important;
				border: 1px solid $color-fitblueelec !important;
				color: #ffffff !important;
			}
		}

		.staff-shortlist {
			.ant-btn-primary {
				/*lilac*/
				color: white !important;
				border: 1px solid $color-lilac !important;
				background-color: $color-lilac !important;

				&:hover {
					/*lilac*/
					color: white !important;
					border: 1px solid lighten($color-lilac, 0%) !important;
					background-color: lighten($color-lilac, 15%) !important;
				}
			}

			.ant-btn-default {
				/*lilac*/
				background-color: white !important;
				border: 1px solid $color-lilac !important;
				color: $color-lilac !important;

				&:hover {
					/*lilac*/
					background-color: $color-lilac !important;
					border: 1px solid $color-lilac !important;
					color: #ffffff !important;
				}
			}
		}
	}

	.ant-dropdown-menu-item {
		color: $color-fitblueelec !important;
	}

	a {
		color: $color-fitblueelec !important;

		&:disabled {
			color: color-black-alpha(0.25) !important;
		}
	}

	.ant-spin-dot-item {
		background-color: $color-fitblueelec !important;
	}

	.ant-btn-link {
		color: $color-fitblueelec !important;

		&:disabled {
			color: color-black-alpha(0.25) !important;
		}
	}
}

.message-templates-list {
	.ant-btn-default {
		background-color: white;
		border: 1px solid transparent;
		border-bottom: 1px solid #ddd;
		color: black;

		&:hover {
			background-color: #eee !important;
			border: 1px solid transparent !important;
			color: $color-fitblueelec !important;
		}
	}
}

.saved-search-form {
	.ant-checkbox-checked {
		.ant-checkbox-inner {
			background-color: $color-fitblueelec !important;
			border: 1px solid $color-fitblueelec !important;
		}
	}

	a,
	.ant-btn-link {
		color: $color-fitblueelec !important;
	}

	.ant-btn-link:disabled {
		color: color-black-alpha(0.25) !important;
	}

}